import { Avatar, Addresses } from 'components/front';
import { FiCalendar, FiHome, FiVideo } from 'react-icons/fi';
import React, { useState } from 'react';
import { AddressDto } from 'entities/address.dto';
import TextTruncate from 'react-text-truncate';
import { sortAddresses } from 'common/addresses/sort-addresses';
import { PlaceDto, PlaceSpecialistDto } from 'entities/place.dto';
import { Button } from 'reactstrap';
import { createRoute, RoutesEnum } from 'common/routes';
import { NoResultsBanner } from './no-results-banner';
import { hasPremiumBasedOnUser } from 'common/premium/has-premium-based-on-expiration-date';

interface Props {
	place: PlaceDto;
	cityFilter?: string;
	onlineFilter: boolean;
	stars: number;
	opinionsCount: number;
}

export const PlaceCard: React.FC<Props> = ({
	place,
	cityFilter,
	onlineFilter,
	stars,
	opinionsCount
}) => {
	const [activeAddress, setActiveAddress] = useState(0);

	if (!place || !place.addressesses) {
		return null;
	}

	const addressesToShow = [...place.addressesses].sort(
		sortAddresses(cityFilter, onlineFilter)
	);

	const [isTruncated, setIsTruncated] = useState<boolean>(true);

	const hasPremium = hasPremiumBasedOnUser(place.user);

	return (
		<div
			className={`grid grid-cols-2 bg-white gap-2
       mb-4 border border-gray-100 rounded-xl p-4`}
		>
			<div
				className={`grid grid-cols-1 gap-2  content-start col-span-2 xl:col-span-1`}
			>
				<div className='grid grid-cols-12 pt-2'>
					<div className='flex gap-2 col-span-12'>
						<Avatar
							paymentExpirationDate={place.user.payment_expiration_at}
							colSpan={2}
							secondaryProfessions={place?.professions_places.map(
								(p: any) => p.profession
							)}
							name={place?.name}
							profession={place?.profession}
							id={place?.id}
							avatar_url={place?.avatar_url}
							stars={stars}
							opinions={opinionsCount}
							route={RoutesEnum.Front_Place}
						/>
					</div>
				</div>

				<div
					className={` text-sm col-span-5 text-gray-500 mt-4 whitespace-pre-wrap`}
				>
					<p>
						{isTruncated && (
							<TextTruncate
								line={3}
								element='span'
								truncateText='…'
								text={place.description}
								textTruncateChild={
									<a
										className='cursor-pointer text-blue-700'
										onClick={() => setIsTruncated(false)}
									>
										Rozwiń
									</a>
								}
							/>
						)}
						{!isTruncated && (
							<span>
								{place.description}
								<div className='mt-4'>
									<a
										className='cursor-pointer text-blue-700'
										onClick={(event) => {
											event.preventDefault();
											setIsTruncated(true);
										}}
									>
										Zwiń
									</a>
								</div>
							</span>
						)}
					</p>

					<div className={`flex flex-wrap border-b text-sm mb-3 gap-x-5 mt-4`}>
						{addressesToShow
							.sort((a, b) => (a.is_online ? 1 : -1))
							.map((address: AddressDto, i: number) => (
								<div
									key={`${i}`}
									className={`font-bold flex items-center justify-center gap-x-1 py-2.5 select-none  
                  ${
										activeAddress === i
											? 'border-b-2 border-blue-600 cursor-auto text-blue-600'
											: 'cursor-pointer'
									}`}
									onClick={() => setActiveAddress(i)}
								>
									<div className={`text-xs`}>
										{address.is_online ? <FiVideo /> : <FiHome />}
									</div>
									{address.is_online ? 'Online' : `Adres ${i + 1}`}
								</div>
							))}
					</div>
					{addressesToShow.length > 0 ? (
						<div>
							<Addresses address={addressesToShow[activeAddress]} />
						</div>
					) : (
						<NoResultsBanner slim content='Placówka nie udostępnia adresu' />
					)}
				</div>
			</div>

			<div className={`xl:pl-4 xl:border-l col-span-2 xl:col-span-1 text-sm`}>
				{!hasPremium && (
					<>
						<p className='text-lg text-center font-medium py-1'>Specjaliści</p>
						<p className='text-center'>Brak specjalistów pod tym adresem</p>
					</>
				)}
				{hasPremium && (
					<>
						<p className='text-lg text-center font-medium pt-1'>Specjaliści</p>
						<div
							className='overflow-auto mt-3 customscroll pr-2'
							style={{ maxHeight: 300 }}
						>
							{place?.places_specialists.filter(
								(placeSpecialist: PlaceSpecialistDto) => {
									return placeSpecialist?.specialist?.calendar_settings_by_specialist_id.find(
										(item) =>
											addressesToShow[activeAddress].id === item.address_id
									);
								}
							).length === 0 && (
								<div className='text-center'>
									Brak specjalistów pod danym adresem
								</div>
							)}
							{place?.places_specialists
								.filter((placeSpecialist: PlaceSpecialistDto) => {
									return placeSpecialist?.specialist?.calendar_settings_by_specialist_id.find(
										(item) =>
											addressesToShow[activeAddress].id === item.address_id
									);
								})
								.map((placeSpecialist: PlaceSpecialistDto) => {
									const { specialist } = placeSpecialist;

									return (
										<div className='grid grid-cols-5 lg:flex xl:grid 2xl:flex mb-5'>
											<div className='col-span-5 lg:col-span-4 xl:col-span-5 2xl:col-span-3 grid grid-cols-5 gap-3 flex-grow'>
												<Avatar
													id={specialist.id}
													colSpan={2}
													name={specialist.shown_name}
													profession={specialist.profession}
													avatar_url={specialist.avatar_url}
													stars={
														specialist.opinions_aggregate.aggregate.avg.rating
													}
													opinions={
														specialist.opinions_aggregate.aggregate.count
													}
													imgSize={80}
													paymentExpirationDate={
														specialist.user.payment_expiration_at
													}
												/>
											</div>
											<div className='col-span-5 lg:col-span-1 xl:col-span-5 2xl:col-span-2 flex w-full 2xl:w-fit justify-end align-center lg:items-start items-center'>
												<Button
													className='w-full  flex items-center gap-2 justify-center mt-4 lg:mt-0 xl:mt-4 2xl:mt-0 2xl:ml-auto lg:text-xs xl:text-sm'
													color='primary'
													tag='a'
													size='almostSm'
													href={createRoute(RoutesEnum.Front_Specialist, {
														id: specialist.id
													})}
												>
													<FiCalendar size={20} />
													Umów&nbsp;wizytę
												</Button>
											</div>
										</div>
									);
								})}
						</div>
					</>
				)}
			</div>
		</div>
	);
};
