import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { gql, useQuery } from '@apollo/client';
import {
	Avatar,
	Footer,
	HomeForm,
	MainHeader,
	MainWrapper,
	PageFullWrapper
} from 'components/front';

import {
	CommonServerSideProps,
	getCommonServerSideProps
} from 'api-common/common-server-side-props';
import { SessionProvider } from 'common/session/session-context';
import { PlaceDto, PlaceSpecialistDto } from 'entities/place.dto';
import { PlacesMap } from 'components/front/places-map';
import {
	mapBooleanLike,
	mapBooleanToQueryParam,
	QueryParamsMapEnum
} from 'common/query-params-filter-enum';
import { PlaceCard } from 'components/front/place-card';
import { createRoute, RoutesEnum } from 'common/routes';
import { serializeQueryString } from 'common/url/serialize-query-string';
import { ProfessionDto } from 'entities/profession.dto';
import Head from 'next/head';

export const getServerSideProps = getCommonServerSideProps;

export const createSearchResultUrlForPlace = (
	specializationFilter: string,
	cityFilter?: string,
	onlineFilter?: boolean,
	redirect?: boolean
) => {
	return (
		createRoute(RoutesEnum.Front_Places) +
		'?' +
		serializeQueryString({
			[QueryParamsMapEnum.Profession]: specializationFilter,
			[QueryParamsMapEnum.City]: cityFilter || '',
			[QueryParamsMapEnum.Online]: mapBooleanToQueryParam(onlineFilter || false)
		})
	);
};

export const createPrettySearchResultUrlForPlace = (
  specializationFilter: string,
  cityFilter?: string,
  onlineFilter?: boolean,
  redirect?: boolean
) => {
  return createRoute(RoutesEnum.Front_PlacesParametrized, {
    city: onlineFilter ? "online" : (cityFilter ? cityFilter.replaceAll(" ", "_") : ''),
    category: !!specializationFilter
      ? specializationFilter.replaceAll(" ", "_")
      : "wszystkie",
  });
};

const QUERY = gql`
	query places_query($where: places_bool_exp) {
		professions(
			where: { parent_id: { _is_null: true }, type: { _eq: "place" } }
		) {
			id
			name
			slug
			parent_id
			type
		}
		addressess_aggregate(
			distinct_on: city
			limit: 30
			where: { is_online: { _eq: false } }
		) {
			nodes {
				city
			}
		}
		places(where: $where) {
			id
			name
			description
			avatar_url
			profession {
				id
				name
			}
			professions_places {
				profession {
					id
					name
					parent_id
				}
			}
			addressesses {
				id
				building_no
				postal_code
				street
				calendar_settings
				city
				geolocation
				is_online
				latitude
				longitude
			}
			user {
				payment_expiration_at
			}
			places_specialists {
				specialist {
					id
					gallery
					shown_name
					avatar_url
					user {
						id
						payment_expiration_at
					}
					calendar_settings_by_specialist_id {
						id
						address_id
						specialist_id
					}
					profession {
						name
						id
					}
					secondary_professions_specialists {
						profession {
							name
							id
							parent_id
						}
					}
					opinions_aggregate {
						aggregate {
							avg {
								rating
							}
							count
						}
					}
				}
			}
		}
	}
`;

export const mapSlugs = (
  slugPath: string[]
) => {
  const professionSlug = slugPath && slugPath[0] ? slugPath[0] : 'wszystkie';
  const citySlug = slugPath && slugPath[1] ? slugPath[1] : '';

  return {
    cityFilter: citySlug != "online" ? citySlug.replaceAll("_", " ") : "",
    professionFilter:
      professionSlug == "wszystkie" ? "" : professionSlug.replaceAll("_", " "),
    onlineFilter: citySlug == "online",
  };
}


const Places: React.FC<CommonServerSideProps> = ({ userSession }) => {
	const router = useRouter();

	if (!userSession) {
		return null;
	}

	const professionSlug = router.query?.slugPath && router.query?.slugPath[0];

  const { cityFilter, onlineFilter, professionFilter } = mapSlugs(router.query?.slugPath as string[]);

	const byCityFilter = [
		{
			addressesses: onlineFilter
				? { is_online: { _eq: true } }
				: { city: { _ilike: `%${cityFilter || ''}%` } }
		}
	];

	const byProfessionFilter = [
		{
			profession: { name: { _ilike: `%${professionFilter || ''}%` } }
		},
		{
			description: { _ilike: `%${professionFilter || ''}%` }
		},
		{
			professions_places: {
				profession: { name: { _ilike: `%${professionFilter || ''}%` } }
			}
		},
		{
			places_specialists: {
				specialist: { shown_name: { _ilike: `%${professionFilter || ''}%` } }
			}
		},
		{
			name: { _ilike: `%${professionFilter || ''}%` }
		}
	];

	const { data, loading } = useQuery(
		// onlineFilter ? ONLINE_QUERY : OFFLINE_QUERY,
		// {
		//   variables: {
		//     profession_slug: `%${professionFilter || ""}%`,
		//     city_slug: `%${cityFilter || ""}%`,
		//     now: dayjs().startOf("day").toISOString(),
		//   },
		// }
		QUERY,
		{
			variables:
				cityFilter || professionFilter
					? {
							where: {
								_or: [
									...(cityFilter ? byCityFilter : []),
									...(professionFilter ? byProfessionFilter : [])
								],
								user: {
									is_activated: { _eq: true }
								}
							}
					  }
					: {
							user: {
								is_activated: { _eq: true }
							}
					  }
		}
	);

	const parentProfession = data
		? data.professions.find((profession: ProfessionDto) => {
				return (
					profession.name.toLowerCase() ===
					professionFilter?.toString().toLowerCase()
				);
		  })
		: null;

	const professionId = parentProfession ? parentProfession.id : 0;

	const [formKey, setFormKey] = useState(0);

	useEffect(() => {
    setFormKey(formKey + 1);
  }, [
    professionSlug,
    router.query.slugPath,
  ]);

	return (
    <SessionProvider userSession={userSession}>
      <Head>
        <meta
          name="keywords"
          content={["placówki", professionFilter, cityFilter, onlineFilter]
            .filter((item) => !!item)
            .join(",")}
        />
        <meta
          name="description"
          content={
            [professionFilter, cityFilter, onlineFilter]
              .filter((item) => !!item)
              .join(",") + " - Placówki na portalu znany.pl"
          }
        />
      </Head>
      <MainWrapper>
        <MainHeader />
        <div className="border-t border-b md:sticky z-20 top-0 bg-white px-2">
          <div className={`mx-auto md:w-11/12 xl:w-10/12`}>
            <div className={`lg:w-11/12 xl:w-10/12 py-3 pt-16 lg:pt-3`}>
              <HomeForm
                searchType="place"
                key={formKey}
                vertical={false}
                filters={{
                  city: cityFilter?.toString(),
                  profession: professionFilter?.toString(),
                  online: onlineFilter,
                }}
                data={data}
                createSearchResultUrl={createPrettySearchResultUrlForPlace}
                specializationPlaceholder="specjalizacja lub nazwa"
              />
            </div>
          </div>
        </div>
        <PageFullWrapper bg="bg-gray-50">
          <div className="mb-8 md:w-11/12 xl:w-10/12 mx-auto pt-6">
            {data?.places?.length === 0 && (
              <div className="p-5 bg-amber-500 mb-2 rounded-lg">
                Brak placówek spełniających kryteria wyszukiwania
              </div>
            )}
            <div className="grid grid-cols-3 gap-4">
              <div className={`col-span-3 md:col-span-2`}>
                {data?.places.map((place: PlaceDto) => (
                  <PlaceCard
                    key={place.id}
                    place={place}
                    onlineFilter={false}
                    stars={
                      place.places_specialists
                        .map((placeSpecialist: PlaceSpecialistDto) => {
                          return placeSpecialist.specialist?.opinions_aggregate
                            .aggregate.avg.rating;
                        })
                        .reduce((acc, curr) => acc + curr, 0) /
                      place.places_specialists.length
                    }
                    opinionsCount={place.places_specialists
                      .map((placeSpecialist: PlaceSpecialistDto) => {
                        return placeSpecialist.specialist?.opinions_aggregate
                          .aggregate.count;
                      })
                      .reduce((acc, curr) => acc + curr, 0)}
                  />
                ))}
              </div>

              <div className={`col-span-1 hidden md:block`}>
                <div className={`grid w-full h-full`}>
                  {data?.places.length ? (
                    <PlacesMap
                      cityFilter={cityFilter?.toString()}
                      places={data?.places}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </PageFullWrapper>
        <Footer />
      </MainWrapper>
    </SessionProvider>
  );
};
export default Places;
